body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:not(.MuiButton-root), a:not(.MuiButton-root):visited, a:not(.MuiButton-root):active {
  color: #E3010F;
  text-decoration: none;
}

.markdown-render > p:first-child {
  margin-top: 0;
}

.excerpt-wrapper {
  position: relative;
}
.excerpt-wrapper .markdown-render::after {
  content: '';
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: -10px;
  left:0;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), white);
}

.companymap {
  width: 100%;
  height: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.partnermap {
  width: 100%;
  height: 100%;
}
.partnerlist {
  background: #fff;
}
.partnerlist.small {
  border-radius: 15px;
  padding-bottom: 100%;
  position: relative;
}
.partnerlist.large {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  height: 100%;
}
.partnerlistinner {
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
}
.partnermap.large {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.partnermap.small {
  border-radius: 15px;
}
.partnermap.small > div:first-child,
.partnermap.small > div:first-child > div:first-child {
  border-radius: 15px;
}

.markdown-input {
  width: 100%;
  display: block;
}
.markdown-input .md-label {
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}
.markdown-input .md-label + div {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.markdown-input .md-button {
  background: none;
  background-image: none;
}
.markdown-input .md-button:hover {
  background: #dedede;
}
.markdown-input .DraftEditor-root > * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}
.markdown-input.has-error .DraftEditor-root {
  border: 1px solid #d32f2f;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tinymce { width: 100%; }
.tinymce-renderer img { max-width: 100%;height:auto; }